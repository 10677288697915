<template>
  <form
    action
    data-vv-scope="country-form"
    @submit.prevent="validateForm()"
    id="country"
  >
    <v-autocomplete
      :disabled="edit == false"
      outlined
      dense
      v-model="value[valueKey]"
      data-vv-validate-on="blur"
      :error-messages="errors.collect('country-form.country')"
      v-validate="rules"
      data-vv-name="country"
      :items="items"
      item-text="name.common"
      item-value="cca2"
      :filter="filter"
      hide-details
      :label="label"
    >
      <template slot="selection" slot-scope="data">
        <!--<img :src="data.item.flag" style="width: 25px; margin-right: 10px" />-->
        <span class="mr-2">{{ data.item.flag }}</span>
        {{ data.item.name.common.slice(0, 15)
        }}{{ data.item.name.common.length > 15 ? "..." : "" }}
      </template>
      <template slot="item" slot-scope="data">
        <!--<img :src="data.item.flag" style="width: 30px; margin-right: 10px" />-->
        <span class="mr-2">{{ data.item.flag }}</span>
        {{ data.item.name.common }}
      </template>
    </v-autocomplete>
  </form>
</template>
<script>
import axios from "axios";
export default {
  name: "CountrySelect",
  props: ["value", "valueKey", "label", "rules", "edit"],
  data() {
    return {
      items: [],
    };
  },
  mounted() {
    this.loadCountry();
  },
  methods: {
    filter(item, queryText, itemText) {
      queryText = queryText.toLowerCase();
      let searchFields = [item.name.common, ...item.altSpellings];

      return searchFields.some((x) => x.toLowerCase().includes(queryText));
    },
    loadCountry() {
      this.url = `https://restcountries.com/v3/all`;
      axios
        .get(this.url)
        .then((response) => {
          this.items = response.data;
        })
        .catch((errorResponse) => {
          //console.log(errorResponse);
        });
    },

    async validateForm() {
      this.errors.clear();
      let form_val = await this.$validator.validateAll("country-form");
      if (form_val) {
        //////console.log("validated");
        return true;
      }
      return false;
    },
  },
};
</script>
<style lang="sass">
#country
  .v-autocomplete.v-select.v-input--is-focused input
    min-width: unset
</style>